import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppLayoutComponent from './layout';
import JoinRoomPage from './white-board/containers/joinRoom';
import CreateRoomPage from './white-board/containers/createRoom';

const joinRoomPath = "/join/:whiteBoardId/:userName/:userToken";
const createRoomPath = "/create/:chatroomId/:userName/:userToken"

interface VuplexWindow extends Window
{
  vuplex: Window
}

declare let window: VuplexWindow;


export const checkReadyAndSendMsg = (messageType: string, messageObject: string) =>
{
  if(window.vuplex != null)
  {
    sendMessageToCSharp(messageType, messageObject);
  }
  else
  {
    window.addEventListener("vuplexready", () => {
      sendMessageToCSharp(messageType, messageObject);
    });
  }
}

function sendMessageToCSharp(messageType: string, messageObj: string) {
    window.vuplex.postMessage({
      type: messageType,
      message: messageObj,
    });
  }

export enum MessageType{
  smartBoardReady = "smartBoardReady"
}

const routerRoom = createBrowserRouter([
  {
    path: '/',
    element: <AppLayoutComponent />,
    children: [
      {path: joinRoomPath, element: <JoinRoomPage/>},
      {path: createRoomPath, element: <CreateRoomPage/>}
    ]
  },
]);
const App: React.FC = () => {
  return <RouterProvider router={routerRoom} />;
};


export default App;
