import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import roomApi from '../api/room.api';
import { JoinRoomDto } from '../models/join-room.dto';
import { RoomResponseDto } from '../models/room-response.dto';
import { AgoraRegion, TokenRole } from '../models/type';
import WhiteboardComponent from '../components/white-board';
import { Loading } from '../components/loading';

const JoinRoomPage: React.FC = () => {
  const { whiteBoardId, userName, userToken } = useParams();
  const region = AgoraRegion.US_SV;
  let data: RoomResponseDto;
  // const [whiteBoardAppId, setWhiteBoardId] = useState<string>();
  const [uuid, setUuid] = useState<string>();
  const [roomToken, setRoomToken] = useState<string>("");  
  
  useEffect(() => {JoinRoom()}, []);
  
  const JoinRoom = async () =>
  {
    const dto: JoinRoomDto = 
    {
      role: TokenRole.Admin,
      whiteBoardId: whiteBoardId as string,
      userToken: userToken as string
    }
    data = await roomApi.get(dto);
    console.log("2");

    setUuid(data.uuid);
    setRoomToken(data.token);
  }

  return (
    <>
    {
      roomToken && region && uuid && userName ? 
      (<WhiteboardComponent region={region} token={roomToken} uuid={uuid} name={userName}/>) : 
      <Loading/>
    }
    </>
  );
};

export default JoinRoomPage;


