import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import roomApi from '../api/room.api';
import WhiteboardComponent from '../components/white-board';
import { JKICreateWhiteBoardDto } from '../models/create-room.dto';
import { RoomResponseDto } from '../models/room-response.dto';
import { AgoraRegion, TokenRole } from '../models/type';
import { Loading } from '../components/loading';
const CreateRoomPage: React.FC = () => {
  const { chatroomId, userName, userToken } = useParams();
  const region = AgoraRegion.US_SV;
  
  const [uuid, setUuid] = useState<string>();
  const [roomToken, setRoomToken] = useState<string>();  
  
  useEffect(() => {CreateRoom()}, []);
  
  const CreateRoom = async ()=> {
    const dto: JKICreateWhiteBoardDto = {
      chatroomId: chatroomId as string,
      role: TokenRole.Admin,
      region: region,
      userToken: userToken as string
    }

    const data: RoomResponseDto  = await roomApi.create(dto);
    setUuid(data.uuid);
    setRoomToken(data.token);
  }

  return (
    <>
    {
      roomToken && region && uuid && userName ? 
      (<WhiteboardComponent region={region} token={roomToken} uuid={uuid} name={userName}/>) : 
      <Loading/>
    }
    </>
  );
};



export default CreateRoomPage;


