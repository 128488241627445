import React from "react";
import { Outlet } from "react-router-dom";


const AppLayoutComponent: React.FC = () => {
    return <>
        <Outlet />
    </>
}

export default AppLayoutComponent;
