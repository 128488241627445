
import { MessageType, checkReadyAndSendMsg } from '../../app';
import { Fastboard, useFastboard } from '@netless/fastboard-react';
import React,{useEffect} from 'react';

type WhiteboardProps = {
  token: string;
  uuid: string;
  region: string;
  name: string;
};

const WhiteboardComponent: React.FC<WhiteboardProps> = ({ token, uuid, region, name }) => {
  
  useEffect(() => {
    checkReadyAndSendMsg(MessageType.smartBoardReady, "OK");
  },[])
  console.log("ONE");
  const fastboard = useFastboard(() => ({
    sdkConfig: {
      appIdentifier: 'jxO3oJUnEey3mDPnXITKOA/DDqG-KfawSnRRA',
      region: region,
    },
    joinRoom: {
      uid: new Date().getUTCMilliseconds().toString(),
      uuid: uuid,
      roomToken: token,
      userPayload:
      {
        nickname: name
      },

    },
    
  }));

  return (
    <div
      style={{
        height: "100vh",
        width:"100wh",
        border: '1px solid',
        background: '#f1f2f3',
      }}>
      <Fastboard app={fastboard} />
    </div>
  );
};

export default WhiteboardComponent;
