const isJson = (headers: Headers) =>
  headers.get('content-type')?.includes('application/json');

export async function fetchResponseHandler<T>(response: Response) {
  const data = isJson(response.headers) && (await response.json());

  if (!response.ok) {
    const error = (data && data.message) || response.status;
    return Promise.reject(error);
  }

  return data as T;
}

export async function fetchErrorHandler(url: string, error: unknown) {
  console.error(url, error);
  return Promise.reject(error);
}
