import { fetchErrorHandler, fetchResponseHandler } from '@core/utils/fetch-handler';
import { JoinRoomDto } from '../models/join-room.dto';
import { RoomResponseDto } from '../models/room-response.dto';
import { JKICreateWhiteBoardDto } from './../models/create-room.dto';
 class RoomApi {
   baseUrl =
     'https://us-central1-seensio-app.cloudfunctions.net/api/private/white-board/room';

   create(dto: JKICreateWhiteBoardDto) {
     const url = this.baseUrl + '/create';
     const options = {
       method: 'POST',
       headers: {
         Authorization: `Bearer ${dto.userToken}`,
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({
         chatroomId: dto.chatroomId,
         role: dto.role,
         region: dto.region,
       }),
     };

     return fetch(url, options)
       .then(async response => fetchResponseHandler<RoomResponseDto>(response))
       .catch(error => fetchErrorHandler(url, error));
   }

   get(dto: JoinRoomDto) {
     const url = this.baseUrl + '/get';
     const options = {
       method: 'POST',
       headers: {
         Authorization: `Bearer ${dto.userToken}`,
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({
         whiteBoardId: dto.whiteBoardId,
         role: dto.role,
       }),
     };

     return fetch(url, options)
       .then(async response => fetchResponseHandler<RoomResponseDto>(response))
       .catch(error => fetchErrorHandler(url, error));
   }
 }
export default new RoomApi();