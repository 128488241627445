export const AgoraRegion = {
    US_SV: 'us-sv',     // Silicon Valley, US, which provides services to North America and South America.
    SG: 'sg',           // Singapore, which provides services to Singapore, East Asia, and Southeast Asia.
    IN_MUM: 'in-mum',   // Mumbai, India, which provides services to India.
    GB_LON: 'gb-lon',   // London, England, which provides services to Europe.
    CN_HZ: 'cn-hz'      // Hangzhou, China, which provides services to the areas not covered by other data centers.
} as const;

type AgoraRegionKey =  keyof typeof AgoraRegion;
export type AgoraRegion = typeof AgoraRegion[AgoraRegionKey];


export const TokenRole = {
    Admin: '0',
    Writer: '1',
    Reader: '2',
} as const 
type TokenRoleKey = keyof typeof TokenRole;
export type TokenRole = typeof TokenRole[TokenRoleKey];
